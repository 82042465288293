import {
  CostInsightsApi,
  ProductInsightsOptions,
  Alert,
} from '@backstage-community/plugin-cost-insights';

import {
  Cost,
  Entity,
  Group,
  MetricData,
  Project,
  DateAggregation,
} from '@backstage-community/plugin-cost-insights-common';

import { CostExplorerClient, GetCostAndUsageCommand, GetTagsCommand } from '@aws-sdk/client-cost-explorer';

import { parseISO, add, format, endOfMonth, startOfMonth } from 'date-fns';

export class CostInsightsClient implements CostInsightsApi {
  private costExplorerClient: CostExplorerClient;

  constructor() {
    this.costExplorerClient = new CostExplorerClient({
      region: 'ap-south-1',
      credentials: {
        accessKeyId: 'AKIAVSPFUPSR3IPO3KXB',
        secretAccessKey: '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',
      },
    });
  }

  private parseRepeatingInterval(intervals: string) {
    const [repeat, period, startDate] = intervals.split('/');
    const repeatCount = parseInt(repeat.substring(1)); // R2 -> 2 repeats
    const periodDays = parseInt(period.substring(1, period.length - 1)); // P30D -> 30 days
    // const start = parseISO(startDate);
    return { repeatCount, periodDays };
  }

  private async fetchDailyCosts(start: string, end: string, project?: string): Promise<any> {
    const command = new GetCostAndUsageCommand({
      TimePeriod: { Start: start, End: end },
      Metrics: ['UnblendedCost'],
      Filter: {
        And: [
          {
            Dimensions: {
              Key: 'LINKED_ACCOUNT',
              Values: ['383270550691'],
            },
          },
          // {
          //   Dimensions: {
          //     Key: 'SERVICE',
          //     Values: ['Amazon Elastic Compute Cloud - Compute'], // EC2 service
          //   },
          // },
          {
            Dimensions: {
              Key: 'REGION',
              Values: ['ap-south-1'],
            },
          },
          ...(project ? [{
            Tags: {
              Key: 'Name',
              Values: [project],
            },
          }] : []),
        ],
      },
      Granularity: 'DAILY',
    });
    try {
      const data = await this.costExplorerClient.send(command);
      if (data.ResultsByTime && data.ResultsByTime.length > 0) {
        return data.ResultsByTime.map((result: any) => ({
          date: result.TimePeriod.Start,
          amount: parseFloat(result.Total?.UnblendedCost?.Amount || '0'),
        }));
      }
      return [];
    } catch (error) {
      console.error('Error fetching daily costs:', error);
      return [];
    }
  }

  private async fetchProjectsByTag() {
    const params = {
      TimePeriod: {
        Start: "2024-09-01",
        End: "2024-09-30"
      },
      TagKey: "Name",
    };
    const command = new GetTagsCommand(params);
    const response = await this.costExplorerClient.send(command);
    return response.Tags;
  }

  private getPreviousAndCurrentAvg(previousCosts: DateAggregation[], currentCosts: DateAggregation[]) {
    const previousTotal = previousCosts.reduce((sum, cost) => sum + cost.amount, 0);
    const previousCount = previousCosts.filter(cost => cost.amount > 0).length || 1;
    const currentTotal = currentCosts.reduce((sum, cost) => sum + cost.amount, 0);
    const currentCount = currentCosts.filter(cost => cost.amount > 0).length || 1;
    const previousAverage = previousTotal / previousCount;
    const currentAverage = currentTotal / currentCount;
    return { previousAverage, currentAverage };
  }

  private calculateRateOfChange(previousCosts: DateAggregation[], currentCosts: DateAggregation[]): number {
    const { previousAverage, currentAverage } = this.getPreviousAndCurrentAvg(previousCosts, currentCosts);
    if (previousAverage === 0) return 0; 
    return ((currentAverage - previousAverage) / previousAverage);
  }
  
  private calculateTotalAmountChange(previousCosts: DateAggregation[], currentCosts: DateAggregation[]): number {
    const { previousAverage, currentAverage } = this.getPreviousAndCurrentAvg(previousCosts, currentCosts);
    return currentAverage - previousAverage;
  }

  private async getDailyCost(id: string, intervals: string, isProject: boolean): Promise<Cost> {
    const { repeatCount, periodDays } = this.parseRepeatingInterval(intervals);
    const adjustedPeriodDays = periodDays === 3 ? 30 * 3 : periodDays * 2;
    // const monthsToSubtract = (2 * adjustedPeriodDays / 30) - 1;
    let start: Date;
    // let start = add(startOfMonth(new Date()), { months: -monthsToSubtract });
    if (adjustedPeriodDays === 90) {
      start = this.getStartDateForQuarter();
    } else {
      const monthsToSubtract = (2 * adjustedPeriodDays / 30) - 1;
      start = add(startOfMonth(new Date()), { months: -monthsToSubtract });
    }
    let previousCosts: DateAggregation[] = [];
    let currentCosts: DateAggregation[] = [];
    let k = 0;
    for (let i = 0; i < repeatCount; i++) {
        let dailyCosts: DateAggregation[] = [];
        for (let j = 1; j <= adjustedPeriodDays / 30; j++) {
            const intervalStart = add(startOfMonth(start), { months: k });
            const intervalEnd = startOfMonth(add(intervalStart, { months: 1 }));
            const formattedStart = format(intervalStart, 'yyyy-MM-dd');
            const formattedEnd = format(intervalEnd, 'yyyy-MM-dd');
            const fetchedCosts = isProject
                ? await this.fetchDailyCosts(formattedStart, formattedEnd, id)  // For project costs
                : await this.fetchDailyCosts(formattedStart, formattedEnd);    // For group costs
            dailyCosts = dailyCosts.concat(fetchedCosts);
            k++;
        }
        if (i === 0) {
            previousCosts = dailyCosts;
        } else {
            currentCosts = dailyCosts;
        }
    }
    const rateOfChange = this.calculateRateOfChange(previousCosts, currentCosts);
    const totalAmountChange = this.calculateTotalAmountChange(previousCosts, currentCosts);
    return {
        id, 
        aggregation: currentCosts, 
        change: {
            ratio: rateOfChange,
            amount: totalAmountChange,
        },
        trendline: {
            slope: 0,
            intercept: 0,
        }
    };
  }

  private getStartDateForQuarter(): Date {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth(); // 0-based (0 = Jan, 11 = Dec)
    const currentQuarter = Math.floor(currentMonth / 3) + 1; // 1-based (1 = Q1, 4 = Q4)
    // Subtract 2 quarters to get the previous previous quarter
    let targetQuarter = currentQuarter - 2;
    // Adjust for crossing year boundaries
    let targetYear = currentDate.getFullYear();
    if (targetQuarter <= 0) {
        targetQuarter += 4; // Move to the previous year
        targetYear -= 1;
    }
    // Start of the first month of the target quarter (i.e., April for Q2)
    const startMonthOfQuarter = (targetQuarter - 1) * 3; // Convert quarter to month index
    return new Date(targetYear, startMonthOfQuarter, 1); // Start of the quarter
  }


  // ----------------------------------------------- APIs ---------------------------------------------------

  
  async getLastCompleteBillingDate(): Promise<string> {
    // return '2024-06-01';
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  }

  async getUserGroups(userId: string): Promise<Group[]> {
    return [
      { id: 'default', name: 'Organisation' },
    ];
  }

  async getGroupProjects(group: string): Promise<Project[]> {
    const fetchedProjects = await this.fetchProjectsByTag() || [];
    return fetchedProjects.map(project => ({
      id: project,
      name: project,
    }));
  }

  async getAlerts(group: string): Promise<Alert[]> {
    return [];
  }

  async getDailyMetricData(
    metric: string,
    intervals: string,
  ): Promise<MetricData> {
    return {
      id: 'remove-me',
      format: 'number',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
    return this.getDailyCost(group, intervals, false);
  }

  async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
    return this.getDailyCost(project, intervals, true);
  }

  async getCatalogEntityDailyCost(
    catalogEntityRef: string,
    intervals: string,
  ): Promise<Cost> {
    return {
      id: 'remove-me',
      aggregation: [],
      change: {
        ratio: 0,
        amount: 0,
      },
    };
  }

  async getProductInsights(options: ProductInsightsOptions): Promise<Entity> {
    debugger
    return {
      id: 'remove-me',
      aggregation: [0, 0],
      change: {
        ratio: 0,
        amount: 0,
      },
      entities: {},
    };
  }
}